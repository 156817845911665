
#noRestaurant .face {
    width: 300px;
    height: 300px;
    border: 4px solid #383a41;
    border-radius: 10px;
    background-color: #ffffff;
    margin: 0 auto;
    margin-top: 100px;
}

@media screen and (max-width: 400px) {
    #noRestaurant .face {
        margin-top: 40px;
        transform: scale(0.8);
    }
}

#noRestaurant .face .band {
    width: 350px;
    height: 27px;
    border: 4px solid #383a41;
    border-radius: 5px;
    margin-left: -25px;
    margin-top: 50px;
}

#noRestaurant .face .band .red {
    height: calc(100% / 3);
    width: 100%;
    background-color: #eb6d6d;
}

#noRestaurant .face .band .white {
    height: calc(100% / 3);
    width: 100%;
    background-color: #ffffff;
}

#noRestaurant .face .band .blue {
    height: calc(100% / 3);
    width: 100%;
    background-color: #5e7fdc;
}

#noRestaurant .face .band:before {
    content: "";
    display: inline-block;
    height: 27px;
    width: 30px;
    background-color: rgba(255, 255, 255, 0.3);
    position: absolute;
    z-index: 999;
}

#noRestaurant .face .band:after {
    /* content: ""; */
    display: inline-block;
    height: 27px;
    width: 30px;
    background-color: rgba(56, 58, 65, 0.3);
    position: absolute;
    z-index: 999;
    right: 0;
    margin-top: -27px;
}

#noRestaurant .face .eyes {
    width: 128px;
    margin: 0 auto;
    margin-top: 40px;
}

#noRestaurant .face .eyes:before {
    content: "";
    display: inline-block;
    width: 30px;
    height: 15px;
    border: 7px solid #383a41;
    margin-right: 20px;
    border-top-left-radius: 22px;
    border-top-right-radius: 22px;
    border-bottom: 0;
}

#noRestaurant .face .eyes:after {
    content: "";
    display: inline-block;
    width: 30px;
    height: 15px;
    border: 7px solid #383a41;
    margin-left: 20px;
    border-top-left-radius: 22px;
    border-top-right-radius: 22px;
    border-bottom: 0;
}

#noRestaurant .face .dimples {
    width: 180px;
    margin: 0 auto;
    margin-top: 15px;
}

#noRestaurant .face .dimples:before {
    content: "";
    display: inline-block;
    width: 10px;
    height: 10px;
    margin-right: 80px;
    border-radius: 50%;
    background-color: rgba(235, 109, 109, 0.4);
}

#noRestaurant .face .dimples:after {
    content: "";
    display: inline-block;
    width: 10px;
    height: 10px;
    margin-left: 80px;
    border-radius: 50%;
    background-color: rgba(235, 109, 109, 0.4);
}

#noRestaurant .face .mouth {
    width: 40px;
    height: 5px;
    border-radius: 5px;
    background-color: #383a41;
    margin: 0 auto;
    margin-top: 25px;
}

#noRestaurant h1 {
    font-weight: 800;
    color: #383a41;
    text-align: center;
    font-size: 2.5em;
    padding-top: 20px;
}

@media screen and (max-width: 400px) {
    #noRestaurant h1 {
        padding-left: 20px;
        padding-right: 20px;
        font-size: 2em;
    }
}

#noRestaurant .btn {
    font-family: "Open Sans";
    font-weight: 400;
    padding: 20px;
    background-color: #5e7fdc;
    color: white;
    width: 320px;
    margin: 0 auto;
    text-align: center;
    font-size: 1.2em;
    border-radius: 5px;
    cursor: pointer;
    margin-top: 80px;
    margin-bottom: 50px;
    transition: all 0.2s linear;
}

@media screen and (max-width: 400px) {
    #noRestaurant .btn {
        margin: 0 auto;
        margin-top: 60px;
        margin-bottom: 50px;
        width: 200px;
    }
}

#noRestaurant .btn:hover {
    background-color: rgba(94, 127, 220, 0.8);
    transition: all 0.2s linear;
}