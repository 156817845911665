@import url('https://fonts.googleapis.com/css2?family=DM+Sans:wght@400;500&display=swap');
#root{
  float:left;
  width: 100%;
}
html,
div,
img,
ul,
li,
a,
p,
ol,
body,
h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
  padding: 0;
  font-family: "DM Sans", sans-serif;
  font-weight: 400;
}
ul,
a,
input {
  text-decoration: none;
  list-style: none;
  text-decoration: none;
  outline: none;
}
:focus {
  outline: 0;
}
.clear {
  clear: both;
}
a:hover {
  text-decoration: none;
}
.middle-bar {
  width: 78%;
  margin: 0 auto;
}
.allheader-bar {
  float: left;
  width: 100%;
  clear: both;
}
.allheader-bar h3 {
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  line-height: 31px;
  color: #333333;
  float: left;
}
.whiter-bag {
  clear: both;
  padding: 50px 20px 50px 20px;
  background: #ffff;
}
.allheader-bar .right {
  float: right;
}

.main-wrapper{
  max-width: 768px !important;
    margin: 0 auto;
    /* overflow-y: auto; */
    overflow-x: hidden;
    padding: 0 1rem;
}
.allheader-bar .selectpicker {
  background: url(images/select-arrow.png) no-repeat;
  background-position: right center;
  border: 1px solid #e6e6e6 !important;
  border-radius: 50px !important;
  padding: 0 10px;
  width: 62px;
  height: 27px;
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 15px;
  color: #828282;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}
.communal-might {
  float: left;
  width: 100%;
  clear: both;
  margin: 20px 0;
}
.rest-title{width:80%}
.lang-bar{float: right;}
.slots button{
    border-radius: 50px;
    text-align: center;
    display: inline-block;
    width: 70px;
    height: 43px;
    border: 1px solid #e6e6e6;
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 40px;
    
    color: #004C45;
    background: #fff;
    margin: 2px 8px 16px 2px;
}

.guests.content{
  text-align: -webkit-center;
}
.guests .input-group{
width: 100%;
max-width: 300px;
}
.guests input.form-control{
  text-align: center;
  border-left: 0;
  border-right: 0;
  z-index: 0 !important;
  box-shadow: none;
}
.guests #input-spinner-left-button, .guests #input-spinner-right-button{
  box-shadow: none;
  border-radius: 50%;
  background-color: #B7C271;
  border: none;height: 45px;
  width: 45px;
  font-size: 29px;
    line-height: 30px;
}
.guests #input-spinner-left-button{
  margin-right: -18px;
}
.guests #input-spinner-right-button{
  margin-left: -18px;
}
.areas button{
  border-radius: 50px;
  text-align: center;
  display: inline-block;
  min-width: 70px;
  /* width: 70px; */
  height: 43px;
  border: 1px solid #e6e6e6;
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 40px;
   
  color: #004C45;
  background: #fff;
  margin: 2px 8px 16px 2px;
}
.communal-might p {
  color: #333333;
  font-style: normal;
  font-weight: 400;
  font-size: 17px;
  line-height: 24px;
}
.gueter-blaker {
  float: left;
  width: 100%;
  clear: both;
}
.clomun-nrt {
 /* padding: 0 30px 0 0;
  float: left;
  width: 100%;
  margin: 0 0 30px 0;*/
}
.guests-opl-action {
  display: none;
}
.clomun-nrt .guest-hver {
  background: #f8f8f8;
  float: left;
  width: 100%;
  clear: both;
  border-radius: 4px;

  position: relative;
  margin-bottom: 4px;
}
.header {box-shadow: 0px 1px 0px rgb(0 0 0 / 10%);  margin-bottom: 25px; padding-left: 5px;}
.header .navbar-brand{
 
  line-height: 52px;
  color: #fff;
}
.header .navbar-brand img{
  max-width: 225px;
  max-height: 40px;
  height: 30px;
 }
 .prego-widget-content{min-height: 77vh;}
 .footer{
  background-color: #004C45; 
  margin-top: 50px; 
  padding: 20px;
  /*position:absolute;*/
  bottom:0;
  width:100%;}
 .footer .powered-by img{ max-width: 225px;
  max-height: 40px;
  height: 30px;}
  .footer .powered-by{ color:#004C45}
.clomun-nrt   .accordion__button span{
  font-style: normal;
  line-height: 48px;
  font-size: 17px;
    text-decoration: none;
    color: #004c45 !important;
  
  padding: 0 10px;
  /*display: block;*/
}
.clomun-nrt .accordion__button img {
  width: 22px;
  margin-right: 8px;
}
.clomun-nrt .accordion__button[aria-expanded="true"] span{
font-weight: 700;
color: #333333 !important;
}
.clomun-nrt .accordion__button:hover img {
  -webkit-filter: grayscale(1);
  filter: grayscale(1);
}

.gueter-blaker .content {
  padding: 21px 0px;
 
  float: left;
  width: 100%;
  clear: both;
  background: #fff;
  position: relative;
  z-index: 999;
}

.gueter-blaker  .contacr-details{
  padding-top:2px !important;
}
.accordion__button:before{display:none  !important}
.content.firstTab{display:block}
.gueter-blaker .content li {
  float: left;
  margin-right: 10px;
  width: 100%;
}
.gueter-blaker .content li:nth-child(3n + 3) {
  margin-right: 0;
}
.gueter-blaker .content li a {
  border-radius: 50px;
  text-align: center;
  display: inline-block;
  width: 95px;
  height: 46px;
  border: 1px solid #e6e6e6;
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 46px;
  color: #004c45;
}
.content.active{display:block; }
.allcountes li {
  margin-bottom: 18px;
}

.clomun-nrt .guest-hver img.z1 {
  position: relative;
  top: -2px;
  width: 18px;
}
.clomun-nrt input::placeholder {
  color: #333333;
  opacity: 1;
}

.clomun-nrt input:-ms-input-placeholder {
  color: #333333;
}

.clomun-nrt input::-ms-input-placeholder {
  color: #333333;
}
.allcountes-namer {
  flex-wrap: wrap;
  display: flex;
  width: 100%;
}
.content .allcountes-namer li {
  margin-bottom: 18px;
}
.content .allcountes-namer li a {
  width: auto;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  color: #004c45 !important;
  padding: 0px 11px 0px 11px;
}
.gueter-blaker .content .allcountes-namer li:nth-child(3n + 3) {
  margin-right: 10px;
}
.contentsecond p {
  color: #333333;
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 20px;
}

.field-wrapper {
  position: relative;
  margin-bottom: 15px;
}

.field-wrapper .field-placeholder {
  font-size: 16px;
  position: absolute;
  bottom: 8px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  pointer-events: none;
  left: 0;
  padding: 0;
  -webkit-transition: transform 150ms cubic-bezier(0.4, 0, 0.2, 1),
    opacity 150ms cubic-bezier(0.4, 0, 0.2, 1);
  transition: transform 150ms cubic-bezier(0.4, 0, 0.2, 1),
    opacity 150ms cubic-bezier(0.4, 0, 0.2, 1);
  
  text-align: left;
  width: 100%;
  color: rgba(0, 0, 0, 0.35);
}
.field-wrapper input:not([disabled]):focus ~ .field-placeholder,
.field-wrapper.hasValue input:not([disabled]) ~ .field-placeholder {
  -webkit-transform: translateY(-19px) translateX(0);
  transform: translateY(-19px) translateX(0);
  font-size: 13px !important;
}
.field-wrapper textarea:not([disabled]):focus ~ .field-placeholder,
.field-wrapper.hasValue textarea:not([disabled]) ~ .field-placeholder {
  -webkit-transform: translateY(-76px) translateX(0);
  transform: translateY(-76px) translateX(0);
  font-size: 13px !important;
}

.field-wrapper select:not([disabled]):focus ~ .field-placeholder,
.field-wrapper.hasValue select:not([disabled]) ~ .field-placeholder {
  -webkit-transform: translateY(-19px) translateX(0);
  transform: translateY(-19px) translateX(0);
  font-size: 13px !important;
  
}

select{
  box-shadow: none !important;
  border-top:0px !important;
  border-left: 0px !important;
  border-right: 0px !important;
  border-radius: 0px !important;
  border-bottom: 1px solid #dfd8d8;
}
.divider-field-wrapper {
  float: left;
  width: 100%;
  clear: both;
  margin-top: 20px;
}
.divider-field-wrapper .field-wrapper {
  float: left;
  width: 45%;
}
.divider-field-wrapper .field-wrapper2 {
  float: right;
}
.field-wrapper input {
  border: 0;
  background: #ffffff;
  border-radius: 0;
  box-shadow: inset 0px -1px 0px rgba(0, 0, 0, 0.2);
  font-family: "DM Sans";
  font-style: normal;
  padding-bottom: 7px;
  font-weight: 400;
  font-size: 17px;
  line-height: 22px;
  color: #000;
  width: 100%;
}

.field-wrapper textarea {
  border: 0;
  background: #ffffff;
  border-radius: 0;
  box-shadow: inset 0px -1px 0px rgba(0, 0, 0, 0.2);
  font-family: "DM Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 17px;
  height: 80px;
  resize: none;
  line-height: 22px;
  color: #000;
  width: 100%;
}

.divider-field-wrapper sup,
.divider-field-wrapper1 sup {
  top: 0;
  font-size: 18px;
  color: #F2827F;
}
.divider-field-wrapper1 {
  float: left;
  width: 100%;
  clear: both;
  margin-top: 20px;
}

#ui-datepicker-div {
  z-index: 9999999 !important;
}
.Additional-info234 {
  color: #333333;
  margin: 20px 0 10px;
  float: left;
  width: 100%;
  clear: both;
  font-style: normal;
  font-weight: 700;
  font-size: 15px;
  line-height: 20px;
}

.new {
  padding: 50px;
}

.form-group-nature {
  display: block;
  margin-bottom: 15px;
  float: left;
  width: 100%;
  clear: both;
}

.form-group-nature input {
  padding: 0;
  height: initial;
  width: initial;
  margin-bottom: 0;
  display: none;
  cursor: pointer;
}

.form-group-nature label {
  position: relative;
  cursor: pointer;
  font-family: "DM Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 20px;
  color: #828282;
  padding-left: 45px;
}

.form-group-nature label:before {
  float: left;
  content: "";
  -webkit-appearance: none;
  height: 30px;
  width: 30px;
  display: inline-block;
  position: relative;
  vertical-align: middle;
  cursor: pointer;
  margin-right: 5px;
  background: #ffffff;
  border: 1px solid #cdcdcd;
  border-radius: 4px;
  margin-left: -45px;
}

.form-group-nature input.isdanger + label:before{
  float: left;
  content: "";
  -webkit-appearance: none;
  height: 30px;
  width: 30px;
  display: inline-block;
  position: relative;
  vertical-align: middle;
  cursor: pointer;
  margin-right: 5px;
  background: #ffffff;
  border: 1px solid red;
  border-radius: 4px;
  margin-left: -45px;
}

.form-group-nature input:checked + label:after {
  content: "";
  display: block;
  position: absolute;
  top: 7px;
  left: 13px;
  width: 6px;
  height: 14px;
  border: solid #004c45;
  border-width: 0 2px 2px 0;
  -moz-transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
}
.form-group-nature input:checked + label:before {
  background: rgba(0, 76, 69, 0.05);
  /* Prego Green */

  border: 1px solid #004c45;
  border-radius: 4px;
}

.form-group-nature span {
  width: 100%;
  float: left;
}
.sender-but {
  border: 0;
  background: #004c45;
  font-family: "DM Sans";
  border-radius: 4px;
  width: 214px;
  height: 50px;
  padding: 15px 40px;
  font-weight: 500;
  font-size: 17px;
  line-height: 20px;
  color: #ffffff;
  margin-top: 20px;
}
.sender-but:hover {
  color: #fff;
  background: #185d55;
}
.done-q {
  position: absolute;
  right: 0;
  top: 0;
}
.done-q img {
  width: 14px !important;
}

.clomun-nrt .guest-hver .form-group-nature label a {
  font-family: "DM Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 20px;
  color: #004c45 !important;
  display: inline-block;
  padding: 0;
}

.xdsoft_datetimepicker .xdsoft_label i,
.xdsoft_datetimepicker .xdsoft_prev,
.xdsoft_datetimepicker .xdsoft_next,
.xdsoft_datetimepicker .xdsoft_today_button {
  background-image: url(images/../images/green-bootstrap-arrow.png) !important;
  background-repeat: no-repeat !important;
  opacity: 1;
}
#calendar {
  margin-left: auto;
  margin-right: auto;
  width: 320px;
  font-family: "Lato", sans-serif;
}
#calendar_weekdays div {
  display: inline-block;
  vertical-align: top;
}
#calendar_content,
#calendar_weekdays,
#calendar_header {
  position: relative;
  width: 320px;
  overflow: hidden;
  float: left;
  z-index: 10;
}
#calendar_weekdays div,
#calendar_content div {
  width: 40px;
  height: 40px;
  overflow: hidden;
  text-align: center;
  background-color: #ffffff;
  color: #bdbdbd;
  font-family: "DM Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
}
#calendar_content {
  -webkit-border-radius: 0px 0px 12px 12px;
  -moz-border-radius: 0px 0px 12px 12px;
  border-radius: 0px 0px 12px 12px;
}
#calendar_content div {
  float: left;
}
#calendar_content div:hover {
  background-color: #f8f8f8;
}
#calendar_content div.blank {
  background-color: #fff;
}
#calendar_header,
#calendar_content div.today {
  zoom: 1;
  filter: alpha(opacity=70);
  opacity: 0.7;
}
#calendar_content div.today {
  color: #004c45;
  background: #fff !important;
}
#calendar_header {
  width: 100%;
  height: 37px;
  text-align: center;
  background-color: #fff !important;
  padding: 12px 0;
  -webkit-border-radius: 12px 12px 0px 0px;
  -moz-border-radius: 12px 12px 0px 0px;
  border-radius: 12px 12px 0px 0px;
  margin-bottom: 30px;
}
#calendar_weekdays {
  margin-bottom: 20px;
}

.clomun-nrt .guest-hver img.af {
  width: auto;
  display: inline-block;
  margin: 0;
}
.icon-chevron-left {
  text-align: center;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  border: 1px solid #dfd8d8;
  display: inline-block;
  line-height: 27px !important;
  float: left;
}
.icon-chevron-right {
  text-align: center;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  box-shadow: 0 0 0 1px #dfd8d8;
  display: inline-block;
  line-height: 27px !important;
  float: right;
}
.caling1 {
  float: left;
}
.caling2 {
  float: right;
}

#calendar h1 {
  display: inline-block;
  display: inline-block;
  margin: 0;
  font-family: "DM Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 17px;
  line-height: 30px;
  text-align: center;
  color: #000000;
  padding: 0;
}
#calendar_weekdays div {
  font-family: "DM Sans" !important;
  font-style: normal !important;
  font-weight: 400 !important;
  font-size: 17px !important;
  line-height: 22px !important;
  text-align: center !important;
  color: #000000 !important;
  width: auto !important;
  height: auto !important;
  text-transform: uppercase;
  margin-right: 13px !important;
}
#calendar_weekdays div:last-child {
  margin-right: 0 !important;
}

.divider-field-wrapper111 .field-wrapper {
  float: left;
  width: 100px;
}
.field-wrapper.field-wrapper-next3 {
  float: right;
  width: 185px;
}


.step-icon{margin-right:10px}
.accordion__panel{padding:0px !important}
.accordion__button{
padding: 0px 10px !important;
background-color: #f8f8f8 !important;
}
.accordion{border: 0px !important;}
.accordion__item{margin-bottom:4px; border-radius: 4px;}
.accordion__item + .accordion__item{border-top: 0px !important;}
.slick-prev, .slick-next{
  color:black !important;
  background-color: black !important;
}
.slotActive{
  background: #B7C271 !important;
    color: #fff !important;
}
.isdanger{
  border-bottom: 1px solid red !important;
}
.isdangerfull{
  border-bottom: 1px solid red !important;
}

.isdangerfull-phone .allow-dropdown{
  border-bottom: 1px solid red !important;
}
.react-datepicker__day--today, .react-datepicker__month-text--today, .react-datepicker__quarter-text--today, .react-datepicker__year-text--today{
  font-weight: 400 !important;
}
.react-datepicker__day--keyboard-selected:hover, .react-datepicker__month-text--keyboard-selected:hover, .react-datepicker__quarter-text--keyboard-selected:hover, .react-datepicker__year-text--keyboard-selected:hover{
  background-color: none;
}
.DatePicker.hasDatepicker .ui-widget-header{border: none;background: none !important;}
.DatePicker.hasDatepicker  .ui-state-default, .ui-widget-content .ui-state-default, .ui-widget-header .ui-state-default, .ui-button, html .ui-button.ui-state-disabled:hover, html .ui-button.ui-state-disabled:active {
  border: none;background: none; color: #004C45;
 

  padding: 10px 0px;
font-style: normal;
font-weight: 400;
font-size: 15px;
line-height: 20px;
/* identical to box height */

 
align-items: center;
text-align: center;
}

.DatePicker.hasDatepicker th span{
  text-transform: uppercase;
  font-weight: 700;
font-size: 12px;
line-height: 16px;
/* identical to box height */

text-align: center;

/* Gray 2 */

color: #4F4F4F;
}
.DatePicker.hasDatepicker  .ui-datepicker-title span
{
  font-weight: 400;
font-size: 17px;
line-height: 22px;
text-align: center;

color: #000000;

} 

.DatePicker.hasDatepicker  td a{
  color: #004C45;
}
 
.DatePicker.hasDatepicker .ui-widget-content{
  border: 0px ;
}

.DatePicker.hasDatepicker .ui-widget-content .ui-widget{
  font-family: 'DM Sans', sans-serif;
}

.DatePicker.hasDatepicker .ui-datepicker{
  width:100%;
    padding: 0px;
}
 
.DatePicker.hasDatepicker .ui-datepicker-prev, .DatePicker.hasDatepicker .ui-datepicker-prev.ui-datepicker-prev-hover{
  background: none;
  border: none;
  right: 15px;
  top: 2px;
}

.DatePicker.hasDatepicker .ui-datepicker-next, .DatePicker.hasDatepicker .ui-datepicker-next.ui-datepicker-next-hover{
  background: none;
  border: none;
  right: 15px;
  top: 2px;
}

.react-datepicker__header {background-color: #fff !important; padding: 12px 0px 8px 0px !important}
.react-datepicker__day--selected, .react-datepicker__day--in-selecting-range, .react-datepicker__day--in-range, .react-datepicker__month-text--selected, .react-datepicker__month-text--in-selecting-range, .react-datepicker__month-text--in-range, .react-datepicker__quarter-text--selected, .react-datepicker__quarter-text--in-selecting-range, .react-datepicker__quarter-text--in-range, .react-datepicker__year-text--selected, .react-datepicker__year-text--in-selecting-range, .react-datepicker__year-text--in-range  /* border-radius: 0.3rem; */
{  background-color: #B7C271 !important;
  color: #fff;
}
.react-datepicker__day--keyboard-selected, .react-datepicker__month-text--keyboard-selected, .react-datepicker__quarter-text--keyboard-selected, .react-datepicker__year-text--keyboard-selected {
  font-weight: 400;
  background-color: #fff !important;
  color: #004c45;
}
.react-datepicker__day-name, .react-datepicker__day, .react-datepicker__time-name{
  color: #004c45 !important;
}
.react-datepicker__day--disabled, .react-datepicker__month-text--disabled, .react-datepicker__quarter-text--disabled, .react-datepicker__year-text--disabled{
  color: #ccc !important;
}
 
 
.react-datepicker__day-names{
  margin-top:9px
}
.react-datepicker__navigation-icon--next{
  left: 0px;
    top: 0px;
    margin-bottom: 5px;
    margin-top: 5px;
}
.DatePicker.hasDatepicker .ui-datepicker-next span.ui-icon, .react-datepicker__navigation-icon--next{
background-position: 4px 0;
    border-radius: 50% !important;
    height: 30px !important;
    width: 30px !important;
    box-shadow: 0 0 0 1px #dfd8d8 !important;
    display: inline-block;
    line-height: 30px;
    position: relative;
    top: 5px;
    right: -18px;
    background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAGQAAAAeCAYAAADaW7vzAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAyJpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuMy1jMDExIDY2LjE0NTY2MSwgMjAxMi8wMi8wNi0xNDo1NjoyNyAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvIiB4bWxuczp4bXBNTT0iaHR0cDovL25zLmFkb2JlLmNvbS94YXAvMS4wL21tLyIgeG1sbnM6c3RSZWY9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9zVHlwZS9SZXNvdXJjZVJlZiMiIHhtcDpDcmVhdG9yVG9vbD0iQWRvYmUgUGhvdG9zaG9wIENTNiAoV2luZG93cykiIHhtcE1NOkluc3RhbmNlSUQ9InhtcC5paWQ6Q0NBRjI1NjM0M0UwMTFFNDk4NkFGMzJFQkQzQjEwRUIiIHhtcE1NOkRvY3VtZW50SUQ9InhtcC5kaWQ6Q0NBRjI1NjQ0M0UwMTFFNDk4NkFGMzJFQkQzQjEwRUIiPiA8eG1wTU06RGVyaXZlZEZyb20gc3RSZWY6aW5zdGFuY2VJRD0ieG1wLmlpZDpDQ0FGMjU2MTQzRTAxMUU0OTg2QUYzMkVCRDNCMTBFQiIgc3RSZWY6ZG9jdW1lbnRJRD0ieG1wLmRpZDpDQ0FGMjU2MjQzRTAxMUU0OTg2QUYzMkVCRDNCMTBFQiIvPiA8L3JkZjpEZXNjcmlwdGlvbj4gPC9yZGY6UkRGPiA8L3g6eG1wbWV0YT4gPD94cGFja2V0IGVuZD0iciI/PoNEP54AAAIOSURBVHja7Jq9TsMwEMcxrZD4WpBYeKUCe+kTMCACHZh4BFfHO/AAIHZGFhYkBBsSEqxsLCAgXKhbXYOTxh9pfJVP+qutnZ5s/5Lz2Y5I03QhWji2GIcgAokWgfCxNvcOCCGKqiSqhUp0laHOne05vdEyGMfkdxJDVjgwDlEQgYQBgx+ULJaWSXXS6r/ER5FBVR8VfGftTKcITNs+a1XpcFoExREIDF14AVIFxgQUS+h520cdud6wNkC0UBw6BCO/HoCYwBhD8QCkQ/x1mwDyD4plh4D6DDV0TAGyo4HcawLIBBSLDkHeH0Mg2yVP3l4TQMZQDDsEOl/MgHQqhMNuE0D+oBh0CIr8MAKyazBH9WyBuKxDWgbXfjNf32TZ1KWm/Ap1oSk/R53UtQ5xTh3LUlMmT8gt6g51Q9p+SobxgJQ/qmsfZhWywGFSl0yBjCLJCMgXail3b7+rumdVJ2YRss4cN+r6qAHDkPWjPjdJCF4n9RmAD/V9A/Wp4NQassDjwlB6XBiCxcJQWmZZb8THFilfy/lfrTvLghq2TqTHrRMTKNJ0sIhdo15RT+RpyWwFdY96UZ/LdQKBGjcXpcc1AlSFEfLmouD+1knuxBDUVrvOBmoOC/rEcN7OQxKVeJTCiAdUzUJhA2Oez9QTkp72OTVcxDcXY8iKNkxGAJXmJCOQwOa6dhyXsOa6XwEGAKdeb5ET3rQdAAAAAElFTkSuQmCC);
}

.DatePicker.hasDatepicker .ui-datepicker-prev span.ui-icon, .react-datepicker__navigation-icon--previous{
  background-position: -15px 0;
  border-radius: 50% !important;
  height: 30px !important;
  width: 30px !important;
  box-shadow: 0 0 0 1px #dfd8d8 !important;
  display: inline-block;
  line-height: 30px;
  position: relative;
  top: 5px;
  right: 5px;
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAGQAAAAeCAYAAADaW7vzAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAyJpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuMy1jMDExIDY2LjE0NTY2MSwgMjAxMi8wMi8wNi0xNDo1NjoyNyAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvIiB4bWxuczp4bXBNTT0iaHR0cDovL25zLmFkb2JlLmNvbS94YXAvMS4wL21tLyIgeG1sbnM6c3RSZWY9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9zVHlwZS9SZXNvdXJjZVJlZiMiIHhtcDpDcmVhdG9yVG9vbD0iQWRvYmUgUGhvdG9zaG9wIENTNiAoV2luZG93cykiIHhtcE1NOkluc3RhbmNlSUQ9InhtcC5paWQ6Q0NBRjI1NjM0M0UwMTFFNDk4NkFGMzJFQkQzQjEwRUIiIHhtcE1NOkRvY3VtZW50SUQ9InhtcC5kaWQ6Q0NBRjI1NjQ0M0UwMTFFNDk4NkFGMzJFQkQzQjEwRUIiPiA8eG1wTU06RGVyaXZlZEZyb20gc3RSZWY6aW5zdGFuY2VJRD0ieG1wLmlpZDpDQ0FGMjU2MTQzRTAxMUU0OTg2QUYzMkVCRDNCMTBFQiIgc3RSZWY6ZG9jdW1lbnRJRD0ieG1wLmRpZDpDQ0FGMjU2MjQzRTAxMUU0OTg2QUYzMkVCRDNCMTBFQiIvPiA8L3JkZjpEZXNjcmlwdGlvbj4gPC9yZGY6UkRGPiA8L3g6eG1wbWV0YT4gPD94cGFja2V0IGVuZD0iciI/PoNEP54AAAIOSURBVHja7Jq9TsMwEMcxrZD4WpBYeKUCe+kTMCACHZh4BFfHO/AAIHZGFhYkBBsSEqxsLCAgXKhbXYOTxh9pfJVP+qutnZ5s/5Lz2Y5I03QhWji2GIcgAokWgfCxNvcOCCGKqiSqhUp0laHOne05vdEyGMfkdxJDVjgwDlEQgYQBgx+ULJaWSXXS6r/ER5FBVR8VfGftTKcITNs+a1XpcFoExREIDF14AVIFxgQUS+h520cdud6wNkC0UBw6BCO/HoCYwBhD8QCkQ/x1mwDyD4plh4D6DDV0TAGyo4HcawLIBBSLDkHeH0Mg2yVP3l4TQMZQDDsEOl/MgHQqhMNuE0D+oBh0CIr8MAKyazBH9WyBuKxDWgbXfjNf32TZ1KWm/Ap1oSk/R53UtQ5xTh3LUlMmT8gt6g51Q9p+SobxgJQ/qmsfZhWywGFSl0yBjCLJCMgXail3b7+rumdVJ2YRss4cN+r6qAHDkPWjPjdJCF4n9RmAD/V9A/Wp4NQassDjwlB6XBiCxcJQWmZZb8THFilfy/lfrTvLghq2TqTHrRMTKNJ0sIhdo15RT+RpyWwFdY96UZ/LdQKBGjcXpcc1AlSFEfLmouD+1knuxBDUVrvOBmoOC/rEcN7OQxKVeJTCiAdUzUJhA2Oez9QTkp72OTVcxDcXY8iKNkxGAJXmJCOQwOa6dhyXsOa6XwEGAKdeb5ET3rQdAAAAAElFTkSuQmCC)
}
.react-datepicker__navigation-icon::before{display:none !important}
.react-datepicker__navigation-icon--next{
  left: 0px !important;
}
.react-datepicker__navigation-icon--next, .react-datepicker__navigation-icon--previous{
  width: 27px !important;
  height: 27px !important;
}
#calendar{
  max-width: 350px;
  width: 100%;
}
/*
.ui-state-active, .ui-widget-content .ui-state-active, .ui-widget-header .ui-state-active, a.ui-button:active, .ui-button:active, .ui-button.ui-state-active:hover{
  background: #B7C271 !important; 
  color:#fff !important;
}
*/
label[for="agreecheckbox"] a{
  color:#004C45;
}

 
.intl-tel-input.allow-dropdown.separate-dial-code .selected-dial-code{
  padding-left: 4px !important;

}

.intl-tel-input.allow-dropdown .flag-container, .intl-tel-input.allow-dropdown.separate-dial-code.iti-sdc-4 .selected-flag{
  width: 100px !important;
}

.allow-dropdown {border-bottom: 1px solid #ced4da !important; width: 100%;}
.iti-flag{}
.selected-flag{padding:6px}
.selected-dial-code{}
.intl-tel-input .flag-container .arrow{font-size: 12px !important;}

.allow-dropdown  .intl-tel-input input, 
.allow-dropdown  .intl-tel-input input[type=text], 
.allow-dropdown  .intl-tel-input input[type=tel],
.phone-numer-wth-code {
  
  border-radius: 0 !important;
  border-bottom: 1px solid #fff !important;
  border-top: 1px solid #fff !important;
  border-left: 1px solid #fff !important;
  border-right: 1px solid #fff !important;
  background-color: #fff;
  box-shadow: none !important;
}
.selected-flag{
  background: #fff !important;
}

.form-control:focus{box-shadow: none !important;}


.hasValue .floating-label,
.phone-focus  .floating-label,
.phone-focus   .floating-label{
  left: 115px !important;
  font-size: 13px;
  transform: translateY(-18px) translateX(0);
}

.hasValue .field-placeholder.floating-label{
  font-size: 13px !important;
}

.hasValue .floating-label-date ,
.date-focus  .floating-label-date ,
.date-focus   .floating-label-date {
  left: 1px !important;
  font-size: 15px;
  transform: translateY(-22px) translateX(0);
}

.floating-label {
  left: 115px !important;
  top: 10px;
  line-height: 14px;
 
}

.field-wrapper select{
  font-size: 17px  !important;
}
.floating-label-date {
  left: 1px !important;
  top: 11px;
  line-height: 14px;
 
}

.intl-tel-input.allow-dropdown.separate-dial-code.iti-sdc-4 input, .intl-tel-input.allow-dropdown.separate-dial-code.iti-sdc-4 input[type=text], .intl-tel-input.allow-dropdown.separate-dial-code.iti-sdc-4 input[type=tel] {
  padding-left: 113px !important;
}

.contact_us_link,.contact_us_link:hover{
  color:#004C45;
  cursor: pointer;
  text-decoration: none;
}

.pool_restaurant_name {
  font-size: 17px;
    font-weight: 500;
    color: #004c45;
    padding: 4px 0;
    margin-top: 12px;
}
.pool_restauarnt_desc
{font-size: 15px;
  color: #333333;
}
.suggession_text {
  color: #333333;
  font-size: 15px;
  text-align: center;
}
.suggession_text button
{
  border: 1px solid #e6e6e6;
    color: #004c45;
    font-size: 15px;
    padding: 6px 14px;
    background: #fff;

    margin-top: 10px;
    margin-bottom: 6px;
}

.sweet-alert  h1, .sweet-alert h2, .sweet-alert h3{
  font-weight: 400 !important;
  font-size: 17px !important;
}
.sweet-alert .btn.btn-link{
  box-shadow: none  !important;
  border-radius: 0px  !important;
  color: #004c45  !important;
  text-decoration: none  !important;
  text-transform: capitalize  !important;
}

.Highlighted a {
  /* background-color: gray !important;
  background-image: none !important;
  color: black !important;
  content: "\25AE"; */
  /* font-weight: bold !important;
  font-size: 12pt; */
}
.Highlighted a::after {
  font-family: 'FontAwesome';
  font-style: italic;
  top: 0;
  left: 23px;
  vertical-align: super;
  content: "i";
  color: black;
  font-weight: 100;
  padding: 6px;
  /* background-color: palevioletred; */
}
 
.noteindex {
  font-size: medium;
  font-weight: 400;
  color: yellowgreen;
}
.HighlightedDesc{width: 18px;margin-left: 11px;}

@media screen and (max-width: 480px) {
  .divider-field-wrapper .field-wrapper {
    width: 100%;
  }
  .field-wrapper.field-wrapper2{
    margin-top: 17px;
  }
}
.clienterror{
  color:red; 
}
.loadercenter{
  align-items: center;
  height: 96px;
  display: block;
  margin-left: auto;
  margin-right: auto;
}
.backto{
  color:#f7726e;
  text-decoration: none;
  padding-bottom: 5px;
}
.backtohomeres{
  padding-bottom: 10px;
}

.singleDayNote {padding: 2px 8px;}
.singleDayNote .heading{float:left; padding-right: 5px;font-weight: 500;}